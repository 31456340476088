
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { CrmCustomerActivity, IdNameActive, CrmCustomerDetailsActivity } from '@/models/CrmCustomers';
import { DateConstants } from '@/models/Constants';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import TimeInput from '@/components/TimeInput.vue';
import CrmCustomerActivityTypesEditorModal from '@/components/CrmCustomers/CrmCustomerActivityTypesEditorModal.vue';
import CrmCustomerActivityLocationsEditorModal from '@/components/CrmCustomers/CrmCustomerActivityLocationsEditorModal.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { SaveCrmCustomerActivityRequest } from '@/models/Interfaces';
import { CrmCustomerActivitySaveChanges } from '@/models/Enums';
import { getFlagChanges } from '@/helper';
import moment from 'moment';
import _ from 'lodash';

export default defineComponent({
    props: {
        crmCustomerId: {
            type: String,
            required: true
        }
    },
    components: {
        OverlayModal,
        Multiselect,
        Calendar,
        TimeInput,
        CrmCustomerActivityTypesEditorModal,
        CrmCustomerActivityLocationsEditorModal
    },
    name: 'CrmCustomerActivityEditorPopup',
    async setup(props, { emit }) {
        const modalCrmCustomerActivityTypesEditor = ref<InstanceType<typeof CrmCustomerActivityTypesEditorModal>>();
        const modalCrmCustomerActivityLocationsEditor = ref<InstanceType<typeof CrmCustomerActivityLocationsEditorModal>>();
        const isOpen = ref(false);
        const activity = ref(new CrmCustomerActivity());
        const activityOrigin = ref(new CrmCustomerActivity());
        const activityDate = ref<Date | null>(null);
        const activityTypes = ref<IdNameActive[]>([]);
        const activityLocations = ref<IdNameActive[]>([]);
        async function loadData() {
            swal.showLoading();
            const responseActivityTypes = await api.getCrmCustomerActivityTypes();
            if (responseActivityTypes?.errorMessage || !responseActivityTypes?.data) {
                swal.fire({
                    icon: 'error',
                    text: responseActivityTypes.errorMessage || 'error'
                });
                return;
            }
            activityTypes.value = responseActivityTypes.data.types;

            const responseActivityLocations = await api.getCrmCustomerActivityLocations();
            if (responseActivityLocations?.errorMessage || !responseActivityLocations?.data) {
                swal.fire({
                    icon: 'error',
                    text: responseActivityLocations.errorMessage || 'error'
                });
                return;
            }
            activityLocations.value = responseActivityLocations.data.locations;
            swal.close();
        }
        async function open(activityParam: CrmCustomerActivity | null = null) {
            const element = document.getElementById('crm-customer-activity-editor');
            if (element) {
                element.classList.remove('was-validated');
            }
            await loadData();
            if (activityParam) {
                activityDate.value = moment(activityParam.date, DateConstants.DATE_FORMAT_SHOW).toDate();
                activity.value.date = activityParam.date;
                activity.value.time = activityParam.time;
                activity.value = activityParam;
            } else {
                activityDate.value = null;
                activity.value = new CrmCustomerActivity();
            }
            activityOrigin.value = _.clone(activity.value);
            isOpen.value = true;
        }

        function openCrmCustomerActivityTypesEditor() {
            modalCrmCustomerActivityTypesEditor.value?.open();
        }

        function openCrmCustomerActivityLocationsEditor() {
            modalCrmCustomerActivityLocationsEditor.value?.open();
        }
        function close() {
            isOpen.value = false;
        }
        function applyActivityTypeChanges(row: IdNameActive) {
            emit('applyChanges', row);
            const existActivityType = activityTypes.value.find(x => x.id == row.id);
            if (existActivityType) {
                existActivityType.active = row.active;
                existActivityType.name = row.name;
            } else {
                activityTypes.value.push(row);
            }
        }
        function onDeleteActivityTypeClick(id: string) {
            const activityTypeIndex = activityTypes.value.findIndex(x => x.id == id);
            if (activityTypeIndex >= 0) {
                activityTypes.value.splice(activityTypeIndex, 1);
            }
        }

        function applyActivityLocationChanges(row: IdNameActive) {
            emit('applyChanges', row);
            const existActivityLocation = activityLocations.value.find(x => x.id == row.id);
            if (existActivityLocation) {
                existActivityLocation.active = row.active;
                existActivityLocation.name = row.name;
            } else {
                activityLocations.value.push(row);
            }
        }
        function onDeleteActivityLocationClick(id: string) {
            const activityLocationIndex = activityLocations.value.findIndex(x => x.id == id);
            if (activityLocationIndex >= 0) {
                activityLocations.value.splice(activityLocationIndex, 1);
            }
        }
        async function saveCrmCustomerActivity() {
            let hasError = false;
            const alertTimeMoment = moment(activity.value.time, 'HH:mm', true);
            if (!alertTimeMoment.isValid()) {
                hasError = true;
            }
            const alertDateMoment = moment(activityDate.value, 'DD/MM/YYYY');
            if (!alertDateMoment.isValid()) {
                hasError = true;
            }
            if (hasError) {
                return null;
            }
            swal.showLoading();
            let changes;
            if (activity.value.id) {
                changes = getFlagChanges(activityOrigin.value, activity.value, CrmCustomerActivitySaveChanges, key => {
                    if (key === 'typeId') return 'Type';
                    if (key === 'locationId') return 'Location';
                    if (key === 'date') return 'DateTime';
                    if (key === 'time') return 'DateTime';
                    return null;
                });
            } else {
                changes = CrmCustomerActivitySaveChanges.All;
            }
            const alertDateTimeString = moment(alertDateMoment)
                .set({
                    hour: alertTimeMoment.hour(),
                    minute: alertTimeMoment.minute()
                })
                .format(DateConstants.SERVER_NODATIME_LOCAL_DATE_TIME_FORMAT);
            const req: SaveCrmCustomerActivityRequest = {
                active: activity.value.active,
                dateTime: alertDateTimeString,
                id: activity.value.id,
                locationId: activity.value.locationId,
                typeId: activity.value.typeId,
                changes: changes,
                remarks: activity.value.remarks,
                subject: activity.value.subject,
                crmCustomerId: props.crmCustomerId
            };
            const apiResponse = await api.saveCrmCustomerActivity(req);
            if (apiResponse.validationErrors?.length) {
                swal.close();
                return null;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return null;
            }
            close();
            swal.close();
            return apiResponse.data.activity;
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            const result = await saveCrmCustomerActivity();
            if (result) {
                emit('saveCrmCustomerActivity', result);
            }
        };

        function updateActivityDate(event: Date) {
            activity.value.date = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        function updateActivityTime(val?: string) {
            if (val) {
                activity.value.time = val;
            }
        }

        return {
            isOpen,
            open,
            close,
            submitForm,
            activity,
            DateConstants,
            activityDate,
            modalCrmCustomerActivityTypesEditor,
            modalCrmCustomerActivityLocationsEditor,
            openCrmCustomerActivityTypesEditor,
            openCrmCustomerActivityLocationsEditor,
            activityTypes,
            activityLocations,
            applyActivityTypeChanges,
            onDeleteActivityTypeClick,
            applyActivityLocationChanges,
            onDeleteActivityLocationClick,
            updateActivityDate,
            updateActivityTime
        };
    }
});
