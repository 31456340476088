
import { defineComponent, ref, PropType } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import CrmCustomerIdNameActiveEditor from '@/components/CrmCustomers/CrmCustomerIdNameActiveEditor.vue';
import { IdNameActive, CrmCustomerActivityTagEditModel } from '@/models/CrmCustomers';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        activityTypes: {
            type: Array as PropType<IdNameActive[]>,
            required: true
        }
    },
    components: {
        OverlayModal,
        CrmCustomerIdNameActiveEditor
    },
    name: 'CrmCustomerActivityTypeEditorModal',
    async setup(props, { emit }) {
        const crmCustomerActivityPropsEditor = ref<InstanceType<typeof CrmCustomerIdNameActiveEditor>>();
        const isOpen = ref(false);
        const { t } = useI18n();
        async function open() {
            swal.showLoading();
            await q.delay(400);
            crmCustomerActivityPropsEditor.value?.setRows(props.activityTypes);
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        function applyChanges(row: IdNameActive) {
            emit('applyChanges', row);
        }
        const onSaveClick = async (item: CrmCustomerActivityTagEditModel): Promise<IdNameActive | null> => {
            swal.showLoading();
            const response = await api.saveCrmCustomerActivityType(item.id, item.copy.active, item.copy.name);
            if (response.validationErrors?.length) {
                const html = response.validationErrors
                    .map(x => {
                        return `<div>${x.key}: ${x.msg}</div>`;
                    })
                    .join(',');
                swal.fire({
                    icon: 'error',
                    html: html
                });
                return null;
            }
            if (response?.errorMessage || !response?.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return null;
            }
            swal.close();
            return response.data.type;
        };
        const onDeleteClick = async (item: CrmCustomerActivityTagEditModel): Promise<boolean> => {
            if (!item.id) {
                return false;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('alert.delete-activity-type', [item.name]),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('businessClient.deleteContact.delete'),
                cancelButtonText: t('businessClient.deleteContact.undelete')
            });
            if (!swalResult.isConfirmed) {
                return false;
            }
            await q.delay(200);
            swal.showLoading();
            const response = await api.deleteCrmCustomerActivityType(item.id);
            if (response?.errorMessage || !response?.data) {
                if (response.errorCode == 101) {
                    if (!item.active) {
                        swal.fire({
                            icon: 'info',
                            title: t('alert.delete-activity-not-active'),
                            customClass: {
                                confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                                cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: t('button.cancel')
                        });
                        return false;
                    }
                    const swalResult = await swal.fire({
                        icon: 'info',
                        title: t('alert.delete-activity-active'),
                        customClass: {
                            confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: t('btn.set-not-active'),
                        cancelButtonText: t('button.cancel')
                    });
                    if (!swalResult.isConfirmed) {
                        return false;
                    }
                    crmCustomerActivityPropsEditor.value?.setNotActiveOrSkip(item);
                    return false;
                }
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return false;
            }
            swal.close();
            emit('onDeleteActivityTypeClick', item.id);
            return true;
        };

        return {
            isOpen,
            open,
            close,
            crmCustomerActivityPropsEditor,
            applyChanges,
            onSaveClick,
            onDeleteClick
        };
    }
});
